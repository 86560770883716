@import '~antd/dist/antd.css';
@import '~leaflet/dist/leaflet.css';
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://cdn.jsdelivr.net/npm/leaflet.locatecontrol@latest/dist/L.Control.Locate.min.css');

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

div:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

body {
    margin: 0px;
}
