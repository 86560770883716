.tab {
    display: inline-block;
    vertical-align: top;
    width: 44px;
    height: 60px;
    cursor: pointer;
    margin-right: 16px;
    text-align: center;
}

.tab.download {
    width: 60px;
}

.tab:last-child {
    margin-right: 0px;
}

.tab.selected {
    cursor: default;
}

.tab .icon {
    width: 44px;
    height: 44px;
    border-radius: 22px;
    margin: 0px auto 0px auto;
    background-color: #707070;
    background-repeat: no-repeat;
    background-position: center;
}

.tab.selected .icon {
    background-color: #F4A740;
}

.tab .name {
    color: #7C7C7C;
    font-family: Poppins,sans-serif;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
}

.tab.selected .name {
    color: #F4A740;
}

.tab.layers .icon {
    background-image: url('./images/navigation-menu-layers-icon.png');
    background-size: 27px;
}

.tab.trends .icon {
    background-image: url('./images/navigation-menu-trends-icon.png');
    background-size: 20px;
}

.tab.download .icon {
    background-image: url('./images/navigation-menu-download-icon.png');
    background-size: 16px;
}

.tab.explore .icon {
    background-image: url('./images/navigation-menu-explore-icon.png');
    background-size: 18px;
}
