@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://cdn.jsdelivr.net/npm/leaflet.locatecontrol@latest/dist/L.Control.Locate.min.css);
.tab-menu_tab__1IlJl {
    display: inline-block;
    vertical-align: top;
    width: 44px;
    height: 60px;
    cursor: pointer;
    margin-right: 16px;
    text-align: center;
}

.tab-menu_tab__1IlJl.tab-menu_download__2HcSl {
    width: 60px;
}

.tab-menu_tab__1IlJl:last-child {
    margin-right: 0px;
}

.tab-menu_tab__1IlJl.tab-menu_selected__1iX-W {
    cursor: default;
}

.tab-menu_tab__1IlJl .tab-menu_icon__2Espu {
    width: 44px;
    height: 44px;
    border-radius: 22px;
    margin: 0px auto 0px auto;
    background-color: #707070;
    background-repeat: no-repeat;
    background-position: center;
}

.tab-menu_tab__1IlJl.tab-menu_selected__1iX-W .tab-menu_icon__2Espu {
    background-color: #F4A740;
}

.tab-menu_tab__1IlJl .tab-menu_name__N74WK {
    color: #7C7C7C;
    font-family: Poppins,sans-serif;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
}

.tab-menu_tab__1IlJl.tab-menu_selected__1iX-W .tab-menu_name__N74WK {
    color: #F4A740;
}

.tab-menu_tab__1IlJl.tab-menu_layers__2rrdW .tab-menu_icon__2Espu {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMA3yB/72AQoEC/kHCvMM9Qfv5NnQAAAalJREFUSMfNlsGagyAQgysgghbk/Z92gT3kgwyCe1oubbFohvkT/Pyr4fwW/rBMfVMeh3q5zGqTks9rjX6ncktpc5/PXj73dZW+PMnW7zrr/ao1le1/1ZF/ngtLr1xcdKR7ptcVlVc/W0tVTyqrLCtUfTy2prbADTYs5oeiNeulUGuoBZPtPpQACtUglqptp4KKm+p1/g2SV14aq7azKLcvbXOUL1MwGC3vGIxltBiMOVorYDBaYQUMRuvm+WA6vYxWMkHmKRYwxhkUYenOvkO99y9a8AHvsTacMQFooXO0x5Qx9kTt6Jxk3wBBAEXo3E67DEFSBrmYp+p9VWdQCKoqT0uI66YuzRkDlWR4kRtMmZ3YIcOzXqu1ncQSkNPqMaqSk/1pxpzv5ba43LNzAQzpct2J0O/xaUeWRldUQ5GFP6UIAjvoo7zHEARQuHN85kIQiuCugBsCy2gChdi5ZW42FNE6moKdUAIoraPZD+1Up5IOC+rDLPrX3xHY0VO9HEvj2N30LPrnZy5H//wdge07H4ggZmdJL0BZGzgKCJRFvQDl3QibH6v8AXmeH5HqHv+pAAAAAElFTkSuQmCC);
    background-size: 27px;
}

.tab-menu_tab__1IlJl.tab-menu_trends__SSwEx .tab-menu_icon__2Espu {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAuCAMAAABkkgs4AAAANlBMVEUAAAD////////////////////////////////////////////////////////////////////xY8b8AAAAEXRSTlMAIN9gn4DPQDDvvxCwj1CgcKUxj3EAAADWSURBVEjHzZTbCoNADAXrujfvzv//bAlKpTZqRMTugyAM2eRk2NctJ+UTsCMW5sJQNVbYg7eyBWDuooP6xHiUVrYEZy5cw3h1vOu5VZAujnc9tySFH9fC/4sW5sL5Ni04p0V8Xot2b7zihBadK8xaBPimHfTbrNCaFjoLcXkr42ZupYDyqY+1yFPREfBHWuRqbjcA3Se3rObrJnamw6LFDitnEHrRQmOr5cYBwXo9tyYCafXf1/p4LasLG7lJz80D4bcvaNXNCavQSd1z0J5ku/WyJSW3N3drDYkNhDYAAAAAAElFTkSuQmCC);
    background-size: 20px;
}

.tab-menu_tab__1IlJl.tab-menu_download__2HcSl .tab-menu_icon__2Espu {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAsBAMAAADyY3eBAAAALVBMVEUAAAD//f3//f3//Pz//Pz/+Pj/+/v//////v7//f3//Pz/+vr//Pz//Pz//v4dmUjXAAAADnRSTlMA36BggCBAEM/vcDC/rwa368gAAACgSURBVCjPY8ANqgLQRfwmYIg8GKwipaEgUAASCQUDBrt3IDAdKMIEZj2EirwGinDARJ6lAcEGoAgriJELFHmAYjITdUW4XSEii+EiHA8DQCIs7zbARLjeiYJE9F4gzPF7GADyRTtcBKhe1O+B3osCmAhYkd4jkBKECAvQj28KUNyz7t07BVQXMr97hO5mSwUkESgY0iIPBZGBJCwlIMBDAKicnFm9qnDgAAAAAElFTkSuQmCC);
    background-size: 16px;
}

.tab-menu_tab__1IlJl.tab-menu_explore__17tv9 .tab-menu_icon__2Espu {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAtCAMAAAAeE1JcAAAANlBMVEUAAAD////////////////////////////////////////////////////////////////////xY8b8AAAAEXRSTlMA34Ag70AQv2CQMJ/PcK9QoLuygTIAAAI9SURBVDjLjZSLlqQgDEQNBMJL6Pr/n90ERR1nZ3ZzulWaS6USaLf/D59zXI8p11rd8N+gSIxmWHQEMJFeIelFBYzCLUbHOneIlJ1A6aVFXjECwnMto8anLQL5pGsJcvupAOhhr3Ig4ubVOlE89StyLHxjCbta02nvY0De4thzZSSzx7Ioom0TXSQAKtetWZ0SlsKSCvpNm0NOGQgR7mG68zEQMjmDzI6ag3tWxm7eVCYgRfQ1IfxswGeOEvxWyeC7yV3kAgeGJbaEfYt3NVoGY0y+EzCdNCWskB1S194Nnm0rjavL1PSRs6awsl1rDeHYC/IGHTIf0gvcSdn00R2DppdiN4cXtYmYxllIb9tFsdOfx0nt8LM35/QsNJPJ18P9ndKd0oJw6fc2yTOoKnmMynnnfOpmjlenT++xqMh5kOc12Pcy5sxVEUY7Mlc6FLsmkqUFJms1+s6MvF35Hcf7GBXpZUoi+Jy9Sfnj7LKzc2CDO9bxWcvNvx4qpq8Yy5RmlOuP1nT8wqb1wES8sIA8sfCgIldzt/sbc+bCE6p/iglyQ/JW/43ZFZLKVAqOoNqR4Kg9Vnbb50QAiBjKB4UzGJZxRSJKswXD1Zr3EM0vqeESv3ToA0r+YT+J5fwWoQOSRyg+pN02Mv3wTrTJI1p+6bxIksR9+1dQtc9vEdUSN8ei7v4OpLxMLWvdvbwFAVjyPor3JlnCcL0BVP2t08HnutfiD673VCF28aeSP2hTbtjR+jnSMS3I7rfo2P8A1sUYyephWqoAAAAASUVORK5CYII=);
    background-size: 18px;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

div:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

body {
    margin: 0px;
}

